<template>
  <div class="about">
    <ATitle text="展会介绍"></ATitle>
    <div class="about-content center rich-text" v-html="info.content"></div>
    <div class="flex cae-title" style="padding-bottom: 40px"><div class="tl"></div><h2>展会亮点</h2><div class="tr"></div></div>
    <div class="flex spot center">
      <div class="spot-item">
        <div class="s-icon flex">
          打造<br>“金名片”
        </div>
        <div class="text">重点展示最新技术、最热应用、最高端产品，单独设立互动体验园，打造全行业最具影响力的“金名片”</div>
      </div>
      <div class="spot-item">
        <div class="s-icon flex">
          覆盖<br>全产业
        </div>
        <div class="text">八大展馆覆盖综合交通产业各种交通运输方式，瞄准行业发展最新热点领域。</div>
      </div>
      <div class="spot-item">
        <div class="s-icon flex">
          政企合作<br>平台
        </div>
        <div class="text">全面展示浙江十一个地市产业发展营商环境，吸引更多优秀企业落户浙江。</div>
      </div>
      <div class="spot-item" style="margin-left: 200px;">
        <div class="s-icon flex">
          引领<br>产业未来
        </div>
        <div class="text">浙江还将与上海、江苏、安徽共同举办第二届长三角综合交通发展大会，共同探讨产业发展前沿问题，引领产业发展方向。</div>
      </div>
      <div class="spot-item" style="margin-right: 200px;">
        <div class="s-icon flex">
          持续性<br>招商引资
        </div>
        <div class="text">政府引导，强力招商，促进产业项目加快落地，签约项目总投资预计达到300亿元。</div>
      </div>
    </div>
    <!-- <dir class="cae center">
      <div class="flex cae-title"><div class="tl"></div><h2>展会论坛</h2><div class="tr"></div></div>
      <div class="flex forum-wrap">
        <div class="forum-item flex">开幕式暨主旨论坛</div>
        <div class="forum-item flex">培育综合交通产业 • 打造<br>交通强国示范区论坛</div>
        <div class="forum-item flex">智慧交通与未来<br>移动出行论坛</div>
        <div class="forum-item flex">2050我心目中的<br>交通强国论坛</div>
        <div class="forum-item flex">长三角区域交通一体化论坛</div>
        <div class="forum-item flex">2022亚运交通科技论坛</div>
        <div class="forum-item flex">未来交通创新发展论坛</div>
      </div>
    </dir> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      info: ''
    }
  },
  created() {
    this.$http.get('/meeting/documents/1156132114735927298',{
    }).then(res => {
      if (res.code === 0) {
        this.info = res.data
      } else {
        this.$Message.warning(res.msg)
      }
    }).catch(err => {
      this.$Message.error(err)
    })
  }
}
</script>

<style lang="less" scoped>
.about {
  color: #FFF;
  .cae-title {
    padding: 10px 0;
    padding-top: 30px;
    h2 {
      font-size: 20px;
      color: #FFF;
    }
    .tl, .tr {
      width: 83px;
      border-bottom: 1px solid #729EF1;
      height: 1px;
      margin: 10px;
    }
  }
  .cae {
    height: 260px;
    margin: 10px auto;
    background: #1D169B;
    .last {
      padding: 20px 0 !important;
      padding-top: 30px;
    }
    .forum-wrap {
      justify-content: flex-start;
      flex-wrap: wrap;
      .forum-item {
        width: 220px;
        height: 60px;
        border: 1px solid #342DB3;
        border-radius: 3px;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        color: #9EB8E9;
        margin: 15px 25px;
        cursor: default;
      }
      .forum-item:first-child {
        background: #0A45AE;
        transition: all 0.2s ease-in-out;
        color: #FFF;
        font-weight: 600;
      }
    }
  }
  // .spot {
  //   justify-content: space-around;
  //   flex-wrap: wrap;
  //   .spot-item {
  //     width: 290px;
  //     height: 300px;
  //     margin: 15px;
  //     .s-icon {
  //       margin: 0 auto;
  //       width: 200px;
  //       height: 179px;
  //       background: url('../assets/spot_bg.png') no-repeat scroll top / 100%;
  //       text-align: center;
  //       font-size: 25px;
  //       font-weight: 500;
  //       letter-spacing: 1px;
  //       padding-bottom: 30px;
  //     }
  //     .text {
  //       font-size: 16px;
  //       font-weight: 400;
  //       color: #9EB8E9;
  //       line-height: 24px;
  //       text-align: justify;
  //     }
  //   }
  // }
}
</style>
<style lang="less">
.about-content {
  line-height: 25px;
  color: #9EB8E9;
  padding: 30px;
}
</style>
